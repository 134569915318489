<style >
.hideColoum{
  display: none !important;
}
</style>
<template>
    <div>
      <Alert v-show="contractChangeQuantity" type="error" show-icon>
        合同变更提醒
        <template slot="desc">
          当前系统包含 <span class="text-red text-20">{{contractChangeQuantity}}</span> 个订单已变更的合同 , 请及时发起合同变更。
          <Icon type="md-arrow-forward" class="m-l-10" /><a @click="SearchContract(1)">点击显示全部订单已变更的合同</a><Icon type="md-arrow-back" />
        </template>
      </Alert>
        <Row :gutter="5" class="m-b-10">
            <i-col :xs="24" :sm="12" :md="6" class="m-b-5">
                <DatePicker size="small" placeholder="合同签订时间"  v-model="signDate" type="daterange" style="width:100%"></DatePicker>
            </i-col>
            <i-col  :xs="24" :sm="12" :md="6" class="m-b-5">
                <DatePicker size="small" placeholder="合同开始结束时间" v-model="publishDate" type="daterange" style="width:100%"></DatePicker>
            </i-col>
            <i-col :xs="24" :sm="12" :md="6" class="m-b-5">
              <i-select  size="small" v-model="contractQuery.contractCategory" placeholder="发布类型" multiple>
              <i-option
                v-for="type in contractTypes"
                :key="type.id"
                :value="type.id"
                >{{ type.name }}</i-option
              >
            </i-select>
                <!-- <Select size="small" placeholder="发布类型" clearable @on-change="handleChangeAdvertiserType">
                  <Option value="1">公益</Option>
                  <Option value="2">商业</Option>
                  <Option value="3">小微</Option>
                </Select> -->
            </i-col>
            <i-col  v-if="isAuth('Contract_Manage_View')" :xs="24" :sm="12" :md="6" class="m-b-5">
                <Select size="small" v-model="contractQuery.companyId" placeholder="所属公司" clearable @on-change="companyChange">
                  <Option v-for="(channel,index) in channellist" :key="index" :value="channel.id">{{channel.name}}</Option>
                </Select>
            </i-col>
            <i-col  v-if="isAuth('Contract_Manage_View')" :xs="24" :sm="12" :md="6" class="m-b-5">
                <Select size="small" v-model="contractQuery.departmentId" placeholder="所属部门" clearable @on-change="departmentChange">
                  <Option v-for="(department,index) in departments" :key="index" :value="department.id">{{department.name}}</Option>
                </Select>
            </i-col>
            <i-col v-if="isAuth('Contract_Manage_View')"  :xs="24" :sm="12" :md="6" class="m-b-5">
                <Select size="small" v-model="contractQuery.userId" placeholder="归属销售" clearable>
                  <Option v-for="user in channelUsers" :key="user.id" :value="user.id">{{ user.name}}</Option>
                </Select>
            </i-col>
            <i-col  :xs="24" :sm="12" :md="6" class="m-b-5">
                <i-select size="small" placeholder="状态" v-model="contractQuery.status" multiple >
                    <i-option v-for="item in statusArray" :key='item.id' :value="item.id">{{item.name}}</i-option>
                </i-select>
            </i-col>
            <i-col  :xs="24" :sm="12" :md="6" class="m-b-5">
                <i-select size="small" placeholder="文件状态" v-model="contractQuery.sealStatus" clearable>
                    <i-option v-for="item in sealstatusArray" :key='item.seal' :value="item.seal">{{item.sealName}}</i-option>
                </i-select>
            </i-col>
            <i-col  :xs="24" :sm="12" :md="6" class="m-b-5">
                <i-input size="small" placeholder="关键字：支持名称查询" v-model="contractQuery.keyword"></i-input>
            </i-col>
            <i-col  :xs="24" :sm="12" :md="6" class="m-b-5">
                <i-button size="small" type="primary" icon='ios-search'  @click="handleSearch">搜索</i-button>
                <i-button size="small"  type="success" class="m-l-5" v-if="isAuth('Contract_DownloadList')" @click="handleExportExcel">导出Excel</i-button>
            </i-col>
        </Row>
        <Row :gutter="8" >
        <i-col span="24">
          <div
            v-for="(item, index) in listContentCategory"
            :key="index"
            class="p-l-10 p-1-10 m-b-2"
            @click="changeChecked( item.serialNumber)"
            :class="
              checkContentCategorys.includes(item.serialNumber)
                ? 'workplatform-radio-button-active'
                : 'workplatform-radio-button'
            "
          >
            {{ item.moduleName }}
          </div>
        </i-col>
      </Row>
        <Table stripe :data="list" :columns="tableColumns" :row-class-name="rowClassName" @on-sort-change="handleTableSort"></Table>
        <div class="paging_style">
          <Page size="small" :total="total" :page-size="contractQuery.pageSize" show-total show-elevator :current="contractQuery.pageNumber" @on-change="handlePageChanged"></Page>
        </div>
        </div>
</template>

<script>
import { getContractPage, deleteContract, invalidContract, getContractModuleList, getChangedContractOrderNum, getcontractSealStatusList } from '@/api/scp/contract'
// import { getOrderAdvertiser } from '@/api/order/order'

import { toMoney } from '@/utils/wnumb_own'
import { GetPublishDay, GetCurrentSchedule } from '@/utils/dateFormat'
import { formatContractStatus } from '@/utils/tagStatus'
import { downloadFileRequest } from '@/utils/download'

import { getCompanyChild } from '@/api/os/company'
import { getSellerPage } from '@/api/os/companyuser'
import { getcategorylist } from '@/api/scp/scpbusiness'

export default {
  data () {
    return {
      contractChangeQuantity: 0,
      channellist: [],
      departments: [],
      channelUsers: [],
      contractTypes: [],
      total: 0,
      list: [],
      tableColumns: [],
      signDate: [],
      publishDate: [],
      statusArray: [],

      listContentCategory: [],
      checkContentCategorys: [1, 5],

      sealstatusArray: []
    }
  },
  methods: {
    rowClassName (row, index) {
      return row.orderChanged ? 'table-conflict-row' : ''
    },
    initsettlementpagecontent () {
      const that = this
      getContractModuleList({}).then((res) => {
        that.listContentCategory = res.filter(x => !x.adminVisible || (x.adminVisible && this.isAuth('Contract_Manage_View')))
        that.initColumns()
      })
    },
    getChangedContractOrderNum () {
      const data = {
        companyId: this.contractQuery.companyId,
        departmentId: this.contractQuery.departmentId,
        userId: this.contractQuery.userId
      }
      getChangedContractOrderNum(data).then(res => {
        this.contractChangeQuantity = res
      })
    },

    getSealStatusArray () {
      getcontractSealStatusList({}).then(res => {
        this.sealstatusArray = res
      })
    },

    SearchContract (showChange) {
      this.contractQuery.orderChanged = showChange
      this.contractQuery.pageNumber = 1
      this.getPage()
    },
    initColumns () {
      const that = this
      that.tableColumns = []
      const tableColumnArray = {
        code: { title: '合同编号', key: 'code', minWidth: 100, align: 'center' },
        name: { title: '合同名称', key: 'name', minWidth: 100, align: 'center' },
        advertiserName: { title: '客户', key: 'advertiserName', minWidth: 100, align: 'center' },
        industryName: { title: '行业', key: 'industryName', minWidth: 80, align: 'center' },
        brandName: { title: '品牌', key: 'brandName', minWidth: 100, align: 'center' },
        contractCategoryName: { title: '发布类型', key: 'contractCategoryName', minWidth: 80, align: 'center' },
        companyName: { title: '所属代理商', key: 'companyName', minWidth: 100, align: 'center' },
        userName: { title: '销售人员', key: 'userName', minWidth: 80, align: 'center' },

        startDate: { title: '合同开始日', key: 'startDate', minWidth: 80, align: 'center' },
        endDate: { title: '合同结束日', key: 'endDate', minWidth: 80, align: 'center' },
        scheduleCount: { title: '档期数量', key: 'scheduleCount', minWidth: 80, align: 'center' },

        goodName: { title: '媒体类型', key: 'goodName', minWidth: 100, align: 'center' },

        usePrice: {
          title: '媒体发布费刊例',
          key: 'usePrice',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.usePrice)
              )
            ])
          }
        },
        signUsePrice: {
          title: '执行价',
          key: 'signUsePrice',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            const disCountText = h(
              'span',
              { class: 'discount' },
              '(' + (params.row.signUsePriceDiscount * 10).toFixed(2) + '折)'
            )

            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.signUsePrice)
              ),
              disCountText
            ])
          }
        },

        // 制作费

        // 上下刊费

        servicePrice: {
          title: '服务费刊例',
          key: 'servicePrice',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.servicePrice)
              )
            ])
          }
        },
        signServicePrice: {
          title: '服务费',
          key: 'signServicePrice',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.signServicePrice)
              )
            ])
          }
        },
        signServicePriceDiscount: {
          title: '服务费折扣',
          key: 'signServicePriceDiscount',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                { class: params.row.signServicePriceDiscount < 1 ? 'discount' : '' },
                (params.row.signServicePriceDiscount * 10).toFixed(2) + '折'
              )
            ])
          }
        },
        amount: {
          title: '合同总额',
          key: 'amount',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.amount)
              )
            ])
          }
        },

        status: {
          title: '状态',
          key: 'status',
          minWidth: 100,
          align: 'center',
          render: (h, data) => {
            if (data.row.invaliding) {
              return h('div', [h('tag', { props: { color: '#a59426' } }, '作废中')])
            } else {
              return formatContractStatus(h, data.row.status, data.row.statusName)
            }
          }
        },
        firstApprovedTime: { title: '首次审批通过时间', key: 'firstApprovedTime', minWidth: 80, align: 'center' },
        lastApprovedTime: { title: '最新变更通过时间', key: 'lastApprovedTime', minWidth: 60, align: 'center' },
        sealStatus: {
          title: '文件状态',
          key: 'sealStatus',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                params.row.sealStatusName
              )
            ])
          }
        },

        invoicedAmount: {
          title: '已开票额',
          key: 'invoicedAmount',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.invoicedAmount)
              )
            ])
          }
        },
        receivedAmount: {
          title: '已回款额',
          key: 'receivedAmount',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.receivedAmount)
              )
            ])
          }
        },

        produceCost: { title: '制作', key: 'produceCost', minWidth: 60, align: 'center' },
        installCost: { title: '上刊', key: 'installCost', minWidth: 60, align: 'center' },
        monitorCost: { title: '监播', key: 'monitorCost', minWidth: 60, align: 'center' },
        uninstallCost: { title: '下刊', key: 'uninstallCost', minWidth: 60, align: 'center' },
        saleCommission: { title: '销售提成', key: 'saleCommission', minWidth: 60, align: 'center' },

        operate:
           {
             title: '操作',
             key: 'action',
             align: 'center',
             width: 80,
             fixed: 'right',
             render: (h, data) => {
               const updateButton = h('a', {
                 style: {
                   marginRight: '5px'
                 },
                 on: {
                   click: () => {
                     this.handleEdit(data.row.id, data.row.versionId)
                   }
                 }
               }, '编辑')
               const deleteButton = h('a', {
                 style: {
                   marginRight: '5px',
                   color: '#ef4f4f'
                 },
                 on: {
                   click: () => {
                     this.handleDelete(data.row.id)
                   }
                 }
               }, '删除')
               const detailButton = h('a', {
                 style: {
                   marginRight: '5px'
                 },
                 on: {
                   click: () => {
                     this.handleDetail(data.row.id, data.row.versionId)
                     this.$store.commit('set_contract_contractId', 0)
                     this.$store.commit('set_contract_contractSetting', false)
                   }

                 }
               }, '详情')
               const settingButton = h('a', {
                 style: {
                   marginRight: '5px'
                 },
                 on: {
                   click: () => {
                     this.$store.commit('set_contract_contractId', data.row.id)
                     this.$store.commit('set_contract_contractSetting', true)
                   }

                 }
               }, '设置')

               const returnBtn = [detailButton]
               if (this.isAuth('Contract_FileSetting') || this.isAuth('Contract_fileUpload') || this.isAuth('Contract_fileDelete')) {
                 returnBtn.push(settingButton)
               }
               if (this.isAuth('Contract_Edit')) {
                 if (data.row.status === 0) {
                   returnBtn.push(updateButton)
                   returnBtn.push(deleteButton)
                 }
                 if (data.row.status === 6) {
                   returnBtn.push(updateButton)
                 }
               }

               return h('div', returnBtn)
             }
           }
      }

      that.checkContentCategorys.forEach(item => {
        const category = that.listContentCategory.filter(x => parseInt(x.serialNumber) === parseInt(item))[0]
        if (category && category.moduleVOList.length) {
          category.moduleVOList.forEach(row => {
            // 验证当前全部列是否和数据库对应
            if (tableColumnArray[row.field]) {
              that.tableColumns.push(tableColumnArray[row.field])
            }
          })
        }
      })

      if (!that.checkContentCategorys.length) {
        this.tableColumns = [
          { title: '资源编号', key: 'resourceCode', minWidth: 100, align: 'center' },
          {
            title: '工单金额',
            key: 'amount',
            minWidth: 60,
            align: 'center',
            render: (h, params) => {
              return h('div', [
                h(
                  'span',
                  {
                    class: 'money'
                  },
                  toMoney(params.row.amount)
                )
              ])
            }
          }]
      }

      this.tableColumns.push(tableColumnArray.operate)
    },
    // 变更多选框选择
    changeChecked (value) {
      const that = this
      if (that.checkContentCategorys.includes(value)) {
        const index1 = that.checkContentCategorys.indexOf(value)
        if (index1 > -1) {
          that.checkContentCategorys.splice(index1, 1)
        }
      } else {
        that.checkContentCategorys.push(value)
      }
      that.initColumns()
    },
    formatDate (date) {
      if (!date) {
        return null
      } else {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      }
    },
    formatSchuedle (data) {
      return {
        value: GetCurrentSchedule(data.startDate, data.endDate),
        days: GetPublishDay(data.startDate, data.endDate)
      }
    },
    initPage () {
      getCompanyChild({ companyId: this.$store.getters.token.userInfo.companyId, type: this.$store.getters.token.userInfo.publisherId === this.$store.getters.token.userInfo.companyId ? 1 : 2, childType: 2, companyType: 1 }).then(res => {
        let array = [{
          id: res.companyId, name: res.companyName
        }]
        if (res.children.length) {
          array = array.concat(this.formatCompanyArray(res.children).filter(x => x.id !== res.companyId))
        }
        this.channellist = array
        if (!this.contractQuery.companyId) {
          if (array.length > 1 && this.isAuth('Contract_Manage_View')) {
            this.contractQuery.companyId = ''
          } else {
            this.contractQuery.companyId = this.$store.getters.token.userInfo.companyId
          }
        }

        this.initdepartments()
      })
      // this.getPage()
    },
    companyChange () {
      this.contractQuery.departmentId = ''
      this.contractQuery.userId = ''
      this.initdepartments()
    },
    departmentChange () {
      this.contractQuery.userId = ''
      this.getChannelUserList()
    },
    initdepartments () {
      this.departments = []
      if (this.contractQuery.companyId) {
        getCompanyChild({ companyId: this.contractQuery.companyId, type: 2, childType: 2, companyType: 2 }).then(res => {
          let array = [{
            id: res.companyId, name: res.companyName
          }]
          if (res.children.length) {
            array = array.concat(this.formatCompanyArray(res.children).filter(x => x.id !== res.companyId))
          }
          this.departments = array
          this.getChannelUserList()
        })
      } else {
        this.getChannelUserList()
      }
    },

    // 重组公司列表结构
    formatCompanyArray (companyTree) {
      let resultArray = []
      companyTree.forEach(item => {
        resultArray.push({ id: item.companyId, name: item.companyName })
        if (item.children.length) {
          resultArray = resultArray.concat(this.formatCompanyArray(item.children))
        }
      })
      return resultArray
    },
    getChannelUserList () {
      const data = {
        companyId: null,
        pageNumber: 1,
        pageSize: 1000
      }
      this.channelUsers = []
      data.companyId = this.contractQuery.departmentId ? this.contractQuery.departmentId : this.contractQuery.companyId
      if (data.companyId) {
        getSellerPage(data).then(response => {
          this.channelUsers = response.list
        })
      } else {
        this.contractQuery.userId = ''
      }
      this.getPage()
    },
    getPage () {
      if (this.contractQuery.status.length > 0) {
        this.contractQuery.statusList = JSON.stringify(this.contractQuery.status)
      } else {
        this.contractQuery.statusList = JSON.stringify(this.statusArray.map(x => { return x.id }))
      }
      if (this.contractQuery.contractCategory.length > 0) {
        this.contractQuery.contractCategoryList = JSON.stringify(this.contractQuery.contractCategory)
      } else {
        this.contractQuery.contractCategoryList = JSON.stringify(this.contractTypes.map(x => { return x.id }))
      }
      this.$store.commit('set_query_contractQuery', this.contractQuery)
      getContractPage(this.contractQuery).then(res => {
        this.list = res.list
        this.total = res.totalRow
      })
    },
    handleChangeAdvertiserType (item) {
      let typeList = []
      if (item === '1') { // 公益
        typeList = [4, 7, 8]
      } else if (item === '2') { // 商业
        typeList = [1, 2, 3, 6]
      } else if (item === '3') { // 小微
        typeList = [5]
      }

      this.contractQuery.advertiserTypeList = JSON.stringify(typeList)
    },
    handleDetail (contractId, versionId) {
      this.$emit('on-detail', contractId, versionId)
    },
    handleEdit (contractId, versionId) {
      this.$emit('on-edit', contractId, versionId)
    },
    handleInvalid (contractId) {
      let desc = ''
      this.$Modal.confirm({
        title: '作废合同',
        render: h => {
          return h('Input', {
            props: {
              value: desc,
              placeholder: '请输入作废理由'
            },
            on: {
              'on-change': event => {
                desc = event.target.value
              }
            }

          })
        },
        onOk: () => {
          invalidContract({ bid: contractId, invalidMark: desc }).then(
            res => {
              if (res && !res.errcode) {
                this.$Notice.success({ desc: '合同作废成功', title: '操作成功' })
                this.getPage()
              }
            }
          )
        }
      })
    },
    handleDelete (contractId) {
      this.$Modal.confirm({
        title: '操作提醒',
        content: '您将删除该合同，点击确认后将删除',
        onOk: () => {
          deleteContract({ contractId: contractId }).then(res => {
            if (res && !res.errcode) {
              this.$Notice.success({
                desc: '合同删除成功'
              })
              this.contractQuery.pageNumber = 1
              this.getPage()
            }
          })
        }

      })
    },
    handlePageChanged (page) {
      this.contractQuery.pageNumber = page
      this.getPage()
    },
    handleSearch () {
      this.contractQuery.pageNumber = 1
      this.contractQuery.orderChanged = null
      this.contractQuery.startDate = this.formatDate(this.publishDate[0])
      this.contractQuery.endDate = this.formatDate(this.publishDate[1])
      this.contractQuery.signStartDate = this.formatDate(this.signDate[0])
      this.contractQuery.signEndDate = this.formatDate(this.signDate[1])
      this.getPage()
    },

    handleTableSort (column) {
      this.contractQuery.pageNumber = 1
      switch (column.key) {
        case 'code':
          this.contractQuery.orderByCodeAsc = (column.order === 'asc')
          break
        case 'amount':
          this.contractQuery.orderByAmountAsc = (column.order === 'asc')
          break
        case 'receivedAmount':
          this.contractQuery.orderByReceivedAmountAsc = (column.order === 'asc')
          break
      }
      this.getPage()
    },
    handleExportExcel () {
      if (this.contractQuery.status.length > 0) {
        this.contractQuery.statusList = JSON.stringify(this.contractQuery.status)
      } else {
        this.contractQuery.statusList = JSON.stringify(this.statusArray.map(x => { return x.id }))
      }
      if (this.contractQuery.contractCategory.length > 0) {
        this.contractQuery.contractCategoryList = JSON.stringify(this.contractQuery.contractCategory)
      } else {
        this.contractQuery.contractCategoryList = JSON.stringify(this.contractTypes.map(x => { return x.id }))
      }
      const query = Object.assign({}, this.contractQuery, {
        subjectTypeList: JSON.stringify([1, 3]),
        selectColumns: JSON.stringify(this.checkContentCategorys)
      })
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v1/contractfile/exportexcel', query, '合同列表.xlsx')
    },
    // 加载可用的状态
    initStatusArray () {
      const that = this
      that.statusArray = [
        { id: 0, name: '草稿' }
      ]
      const manageStatus = [
        { id: -1, name: '已废弃' },
        { id: 4, name: '待审批' },
        { id: 5, name: '已通过' },
        { id: 6, name: '已拒绝' }
      ]
      if (that.isAuth('Contract_Sale_View')) {
        that.statusArray = that.statusArray.concat(manageStatus)
      } else {
        that.statusArray = manageStatus
      }
    },
    // 加载可用的状态
    initcontractType () {
      const that = this
      getcategorylist({}).then(res => {
        that.contractTypes = res
      })
    }

  },
  created () {
    this.contractQuery.userId = this.isAuth('Contract_Manage_View') ? this.contractQuery.userId : this.$store.getters.token.userInfo.userId
    this.initsettlementpagecontent()
    this.getChangedContractOrderNum()
    this.getSealStatusArray()
    this.initStatusArray()
    this.initcontractType()
    this.initPage()
  },
  computed: {
    contractQuery () {
      return this.$store.state.contractQuery.contractQuery
    },
    beginUpdate () {
      return this.$store.state.contract.beginUpdate
    }
  },
  watch: {
    beginUpdate () {
      this.initPage()
    }
  }
}
</script>
